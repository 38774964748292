export const FirstCategory = [
  {
    prettyName: `1'ere`,
    categoryName: 'Ones',
    maxValue: 5,
    disabled: false
  },
  {
    prettyName: `2'ere`,
    categoryName: 'Twos',
    maxValue: 10,
    disabled: false
  },
  {
    prettyName: `3'ere`,
    categoryName: 'Threes',
    maxValue: 15,
    disabled: false
  },
  {
    prettyName: `4'ere`,
    categoryName: 'Fours',
    maxValue: 20,
    disabled: false
  },
  {
    prettyName: `5'ere`,
    categoryName: 'Fives',
    maxValue: 25,
    disabled: false
  },
  {
    prettyName: `6'ere`,
    categoryName: 'Sixes',
    maxValue: 30,
    disabled: false
  },
]

export const SecondCategory = [
  {
    prettyName: '1 Par',
    categoryName: 'OnePair',
    maxValue: 12,
    disabled: false
  },
  {
    prettyName: '2 Par',
    categoryName: 'TwoPairs',
    maxValue: 22,
    disabled: false
  },
  {
    prettyName: '3 ens',
    categoryName: 'ThreeOfAKind',
    maxValue: 18,
    disabled: false
  },
  {
    prettyName: '4 ens',
    categoryName: 'FourOfAKind',
    maxValue: 24,
    disabled: false
  },
  {
    prettyName: 'Lille Straight',
    categoryName: 'SmallStraight',
    maxValue: 15,
    disabled: false
  },
  {
    prettyName: 'Stor Straight',
    categoryName: 'LargeStraight',
    maxValue: 20,
    disabled: false
  },
  {
    prettyName: 'Hus',
    categoryName: 'FullHouse',
    maxValue: 28,
    disabled: false
  },
  {
    prettyName: 'Chance',
    categoryName: 'Chance',
    maxValue: 30,
    disabled: false
  },
  {
    prettyName: 'Yatzy',
    categoryName: 'Yatzy',
    maxValue: 50,
    disabled: false
  },
]