import { Route, Routes } from "react-router"
// Pages
import TopNavigation from './components/navigation/TopNavigation'
import HomePage from './pages/HomePage'
import Yatzy from './pages/Yatzy/Yatzy'
// styling
import './App.css'

function App() {
  const isLoggedIn = true;
  return (
    <div className="App">
    
        { isLoggedIn && <TopNavigation /> }
        <Routes>
          <Route path={""} element={<HomePage/>} />
          <Route path={'/Yatzy'} element={<Yatzy/>} />
        </Routes>
    
    </div>
  )
}

export default App
