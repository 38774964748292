import React, { useEffect, useState } from 'react'

import { Grid, TextField, IconButton, Divider } from '@mui/material'
import { RemoveCircleOutline, AddCircleOutline, CloseOutlined } from '@mui/icons-material'
import Spacer from '../../components/Spacer/Spacer'

import { FirstCategory, SecondCategory } from './YatzyProperties'

import './Yatzy.styl'

const YatzyGame = () => {
  const [players, setPlayers] = useState([
    { name: 'Spiller 1', firstCategoryScores: {}, secondCategoryScores: {}, disabledCategories: {} },
  ])
  const [numberOfPlayers, setNumberOfPlayers] = useState(1)

  const updateScore = (playerIndex, placement, category, value) => {
    setPlayers((prevPlayers) => {
        const newPlayers = [...prevPlayers]
        if (placement === 'first'){
            newPlayers[playerIndex].firstCategoryScores = {
                ...newPlayers[playerIndex].firstCategoryScores,
                [category]: value,
            }
        }
        if (placement === 'second'){
            newPlayers[playerIndex].secondCategoryScores = {
                ...newPlayers[playerIndex].secondCategoryScores,
                [category]: value,
            }
        }
      return newPlayers
    })
  }

  const disableCategory = (playerIndex, category, value) => {
    setPlayers((prevPlayers) => {
      const nextPlayers = [...prevPlayers]
      nextPlayers[playerIndex].disabledCategories = {
        ...nextPlayers[playerIndex].disabledCategories,
        [category]: value,
      }
      return nextPlayers
    })
  }

  useEffect(() => {
    setPlayers(
      Array.from({ length: numberOfPlayers }, (_, index) => ({
        name: `Spiller ${index + 1}`,
        firstCategoryScores: {}, 
        secondCategoryScores: {},
        disabledCategories: {}
      }))
    )
  }, [numberOfPlayers])

  const handlePlayerNameChange = (e, playerIndex) => {
    const newPlayers = [...players]
    newPlayers[playerIndex].name = e.target.value;
    setPlayers(newPlayers)
  }

  const calculateFirstCategory = (player) => {
    const firstCategoryScoreValues = Object.values(player.firstCategoryScores)
      .filter((value) => typeof value === 'number')
      .map((value) => value)
    return firstCategoryScoreValues.reduce((sum, value) => sum + value, 0)
  }

  const calculateSecondCategory = (player) => {
    const secondCategoryScoreValues = Object.values(player.secondCategoryScores)
      .filter((value) => typeof value === 'number')
      .map((value) => value)
    return secondCategoryScoreValues.reduce((sum, value) => sum + value, 0)
  }

  const calculateTotal = (player) => {
    const sum = calculateFirstCategory(player)
    const bonus = sum >= 63 ? 50 : 0
    const total = calculateSecondCategory(player) + sum + bonus
    return total
  }

  return (
    <div >
    <Spacer/>
      <Grid container >
        <Grid item xs={12} >
          <h1>Yatzy 5 Terninger</h1>
        </Grid>
        <Grid item xs={12} >
          <label>
            Antal Spillere:
          </label>
        </Grid>
        <Grid item xs={12} >
          <div className='yatzy__player-action__buttons' >
            <IconButton
              size='large'
              onClick={() => setNumberOfPlayers(numberOfPlayers >= 2? numberOfPlayers - 1 : 1)}
            >
              <RemoveCircleOutline fontSize='large' />
            </IconButton>
            {numberOfPlayers}
            <IconButton 
              size='large'
              onClick={() => setNumberOfPlayers(numberOfPlayers + 1)}
            >
              <AddCircleOutline fontSize='large' />
            </IconButton>
          </div>
        </Grid>
        
        
        {players.map((player, index) => (
          <Grid item xs={12} >
            <div key={index}>
              <Grid item xs={12} >
                <h2>Spiller {index + 1}</h2>
              </Grid>
              <TextField
                size='small'
                variant='standard'
                label='Navn'
                value={player.name}
                onChange={(e) => handlePlayerNameChange(e, index)}
              />
              <Spacer/>
              <Grid item sx={{ display: 'flex', justifyContent: 'center'}} xs={12} >
              <table>
                <thead>
                <tr>
                    <th>Maximum Point</th>
                    <th></th>
                    <th>Score</th>
                </tr>
                </thead>
                <tbody>
                {Object.values(FirstCategory).map((category) => (
                  <tr key={category.categoryName}>
                  <td>{category.prettyName}</td>
                  <td>{'('}{category.maxValue}{')'}</td>
                  <td>
                    <TextField
                      size='small'
                      disabled={player.disabledCategories[category.categoryName] || false}
                      variant={player.disabledCategories[category.categoryName] === true ? 'filled' : 'outlined'}
                      sx={{ width: '50px' }}
                      value={player.firstCategoryScores[category.categoryName] || ''}
                      onChange={(e) =>
                        updateScore(
                          index,
                          'first',
                          category.categoryName,
                          parseInt(e.target.value, 10)
                        )
                      }
                    />
                  </td>
                  <td>
                  {player.disabledCategories[category.categoryName] === true? 
                    <IconButton
                      onClick={() => disableCategory(
                        index,
                        category.categoryName,
                        false
                      )}
                    >
                      <CloseOutlined/>
                    </IconButton> 
                  :
                    <IconButton
                      onClick={() => disableCategory(
                        index,
                        category.categoryName,
                        true
                      )}
                    >
                      <RemoveCircleOutline/>
                    </IconButton> 
                    }
                  </td>
                  </tr>
                ))}
                <tr>
                    <td>Sum</td>
                    <td>{'(min. 63)'}</td>
                    <td>
                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px' }} >
                        {calculateFirstCategory(player)}
                      </div>  
                    </td>
                </tr>
                <tr>
                  <td>Bonus</td>
                  <td>{'50'}</td>
                  <td>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px' }} >
                      {calculateFirstCategory(player) >= 63 ? 50 : 0} 
                    </div>  
                  </td>
                </tr>
                {Object.values(SecondCategory).map((category) => (
                  <tr key={category.categoryName}>
                  <td>{category.prettyName}</td>
                  <td>{'('}{category.maxValue}{')'}</td>
                  <td>
                    <TextField
                      size='small'
                      variant={player.disabledCategories[category.categoryName] === true ? 'filled' : 'outlined'}
                      sx={{ width: '50px' }}
                      disabled={player.disabledCategories[category.categoryName] || false}
                      value={player.secondCategoryScores[category.categoryName] || ''}
                      onChange={(e) =>
                        updateScore(
                          index,
                          'second',
                          category.categoryName,
                          parseFloat(e.target.value, 10)
                        )
                      }
                    />
                  </td>
                  <td>
                   {player.disabledCategories[category.categoryName] === true? 
                    <IconButton
                      onClick={() => disableCategory(
                        index,
                        category.categoryName,
                        false
                      )}
                    >
                      <CloseOutlined/>
                    </IconButton> 
                  :
                    <IconButton
                      onClick={() => disableCategory(
                        index,
                        category.categoryName,
                        true
                      )}
                    >
                      <RemoveCircleOutline/>
                    </IconButton> 
                    }
                  </td>
                  </tr>
                ))}
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px' }} >
                      {calculateTotal(player)}
                    </div>
                    <Divider/>
                  </td>
                </tr>
                </tbody>
              </table>
              </Grid>
              <Spacer/>
              <Divider/>
            </div>
            </Grid>
          
        ))}
        <Spacer/>
      </Grid>
    </div>
  )
}

export default YatzyGame
