import { useState } from 'react';
import { Container, Grid, Typography, Button } from '@mui/material';
import SvirrensDialog from '../components/SvirrensDialog';
import Picture from '../images/SvirrensText.png';

function HomePage() {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <Container sx={{ marginTop: 8 }} >
            <Grid container columnSpacing={6} >
                <Grid item sm={12} xs={12} >
                    <img src={Picture} alt='svirrens' height={300} />
                </Grid>
                <Grid item sm={12} xs={12} >
                    <Typography variant={'h2'} >
                        Welcome to Svirrens.com my people!
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12} sx={{ padding: 4 }} >
                    <a
                        className="App-link"
                        href="https://www.linkedin.com/in/nikolaj-morgen-4a8568145/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Press here to look at the no. 1 coder in Hvidovre! 
                    </a>
                </Grid>
                <Grid item sm={12} xs={12} >
                    <Button fullWidth variant={"outlined"} onClick={() => setOpen(true) } >
                        See who approves
                    </Button>
                </Grid>
                <Grid item sm={12} xs={12} sx={{ marginTop: 8 }} >
                    <Typography variant={"h6"} >
                        Der kommer noget fedt, til alle mine hjemmedrenge inden klokken slår 2023!
                    </Typography>
                </Grid>
            </Grid>
            <SvirrensDialog open={open} onClose={() => handleClose()} title={"This guy approves!"} />
        </Container>
    );
};

export default HomePage;