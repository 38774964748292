import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import approvesPic from '../images/approves.png';

interface Props {
    title: string;
    open: boolean;
    onClose: any;
};

function SvirrensDialog(props: Props) {
    return(
        <Dialog open={props.open} onClose={props.onClose} >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <img src={approvesPic} alt={"approves"} height={400} />
            </DialogContent>
        </Dialog>
    );
};

export default SvirrensDialog;