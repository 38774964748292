import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
// Images
import SvirHomeText from '../../images/SvirHomeText.png'
//
import { AppBar, Box, Toolbar, Container, Tooltip, Button } from '@mui/material'

const pages = ['Yatzy']

const TopNavigation = () => {
  const navigate = useNavigate()

  const handleNavigate = (navigateTo: string) => {
    navigate('/' + navigateTo)
  }

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#32323B" }} >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          <Tooltip title={"Hjem"} >
            <Link style={{ textDecoration: "none", color: "white" }} to={""}>
              {<img src={SvirHomeText} alt={"svirrens home"} height={48} />
              }
            </Link>
          </Tooltip>
 
          <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                sx={{ my: 2, color: '#fff', display: 'block' }}
                onClick={() => handleNavigate(page)}
              >
                {page}
              </Button>
            ))}
            </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default TopNavigation